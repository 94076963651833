import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import Img from "gatsby-image"
import webinar1_slides from '../downloads/webinar1_slides.pdf'
import webinar2_notes from '../downloads/webinar2_notes.pdf'
import webinar18_slides from '../downloads/webinar18_slides.pdf'
import webinar18_infographic from '../downloads/webinar18_infographic.pdf'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import BackToTopButton from '../components/BackToTopButton'
import { FaSearch } from 'react-icons/fa'
import { JsonLd } from "react-schemaorg";
import { BreadcrumbList } from "schema-dts";

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "surgeoncircle_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    webinar1: file(relativePath: { eq: "webinar_1.jpg" }) {
      childImageSharp {
        fixed(width:200, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    webinar2: file(relativePath: { eq: "webinar_2.jpg" }) {
      childImageSharp {
        fixed(width:200, quality: 100) {
          ...GatsbyImageSharpFixed
      }
    }
  }
  webinar3: file(relativePath: { eq: "webinar_3.jpg" }) {
    childImageSharp {
      fixed(width:200, quality: 100) {
        ...GatsbyImageSharpFixed
    }
  }
}
webinar4: file(relativePath: { eq: "webinar_4.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar5: file(relativePath: { eq: "webinar_5.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar6: file(relativePath: { eq: "webinar_6.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar7: file(relativePath: { eq: "webinar_7.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar8: file(relativePath: { eq: "webinar_8.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar9: file(relativePath: { eq: "webinar_9.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_10: file(relativePath: { eq: "webinar_10.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_11: file(relativePath: { eq: "webinar_11.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_12: file(relativePath: { eq: "webinar_12.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_13: file(relativePath: { eq: "webinar_13.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_14: file(relativePath: { eq: "webinar_14.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_15: file(relativePath: { eq: "webinar_15.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_16: file(relativePath: { eq: "webinar_16.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_17: file(relativePath: { eq: "webinar_17.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_18: file(relativePath: { eq: "webinar_18.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_19: file(relativePath: { eq: "webinar_19.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_20: file(relativePath: { eq: "webinar_20.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_21: file(relativePath: { eq: "webinar_21.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_22: file(relativePath: { eq: "webinar_22.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
webinar_23: file(relativePath: { eq: "webinar_23.jpg" }) {
  childImageSharp {
    fixed(width:200, quality: 100) {
      ...GatsbyImageSharpFixed
  }
}
}
}
`

export default ({data}) => {
  const searchfunction = () => {
    if (typeof window !== `undefined`) {
    // Declare variables
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();
    ul = document.getElementById('myUL');
    li = ul.getElementsByClassName('myli');

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }}
  return (
  <>
   <JsonLd<BreadcrumbList>
      item={{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
      "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "PanSurg",
      "item": "http://www.pansurg.org"
    },{
      "@type": "ListItem",
      "position": 2,
      "name": "About",
      "item": "http://www.pansurg.org/about"
    },{
      "@type": "ListItem",
      "position": 3,
      "name": "Archive",
      "item": "http://www.pansurg.org/archive"
    }] }} />
<GatsbySeo title='PanSurg Archive'
      description='Review our collection of webinars held during the COVID-19 pandemic'/>
  <Hero title="Webinar Archive"
        subtitle="Review our collection of webinars held during the COVID-19 pandemic"
        fluid={data.hero.childImageSharp.fluid}
        breadcrumbs
        secondcrumb = 'Academy'
        secondlink = '/webinars'
        activecrumb = 'Archive'
  />

  <section className="section" style={{margin:'0rem 0rem'}}>
    <div className="container">
      <div className="content is-thin">
      <p>Here, you can watch our previous webinars and access the slides or notes.
      To watch them, click on the poster to watch the webinar.
      If you didn't register when the webinar was held initially, you will
      need to do so to get access.</p>
      <p>Alternatively you can view all of our webinars, symposia and video material on our <a href="https://www.youtube.com/c/PanSurgCollaborative">YouTube channel</a>.</p>
      </div>
      <hr className="is-divider"/>
    </div>
  </section>
  <section className="section is-small">
    <div className="container">

    <div className="block">
    <p className="control has-icons-left">
      <input className="input" type="text" placeholder="Search for Webinars, Speakers, Tags..." id="myInput" onKeyUp={searchfunction} />
      <span className="icon is-left">
       <FaSearch />
      </span>
  </p></div>

  <ul id="myUL">

  <li className="myli">
      <a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/digital-health-after-covid-19">
Webinar 23: Aftershock; Returning to elective surgery, a Live Peer Review</a></p>
<div className="archive is-flex-tablet">
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/digital-health-after-covid-19">
    <Img className="bounce" fixed={data.webinar_23.childImageSharp.fixed} />
  </a>
  <br />
  {/* <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=QhTj32RxP-k">Youtube Video</a>
   <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
<p><b>The COVID-19 surgical plan: An analysis of surgical service disruption during COVID-19.</b><br />
Chairs: James Kinross and Aimee DiMarco</p>
<p><b>The Lunch time Session: How will diagnostic and prevention strategies for surgical chronic disease change after COVID-19?</b><br />
Chairs: Sheraz Markar and Elaine Burns</p>
<p><b>The Evening Session: How should we re-design elective surgical services during and after Covid-19?</b><br />
Chairs: Guy Martin and Tan Arulampalam</p>
  </div>
</div>
</div>
</a>
<br />
</li>

  <li className="myli">
      <a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/returning-to-essential-surgery-in-north-and-west-africa-after-covid-19">
Webinar 22: Returning to essential surgery in North and West Africa after COVID-19</a></p>
<div className="archive is-flex-tablet">
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/returning-to-essential-surgery-in-north-and-west-africa-after-covid-19">
    <Img className="bounce" fixed={data.webinar_22.childImageSharp.fixed} />
  </a>
  <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=WdPtz5a5Sng">Youtube Video</a>
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
<p><strong>Host:</strong> Dr. Ademola Adeyeye - General Surgeon/surgical oncologist, European Society for Surgical Oncology (Rep. for Nigeria)</p>
<p><strong>Host:</strong> Dr. Mostafa Shalaby - Lecturer and consultant colorectal surgeon, Mansoura University Director of Continuing medical education Unit</p>

<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Prof. Charles Adeyinka Adisa MD FWACS, FACS</strong>, Professor of Surgery and medical oncologist, Nigeria</li>
      <li><strong>Prof. Khaled Madbouly MD, FRCS (GLASG), FACS, MBA</strong>, Prof. of Colorectal Surgery, University of Alexandra, Egypt. Secretary General of ISUCRS, President of Egyptian Society of Colon & Rectal Surgeons</li>
    </ul>
  </div>
</div>
</div>
</a>
<br />
</li>

  <li className="myli">
      <a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/global-health-security-pandemics-amr-and-climate-change">
Webinar 21: Global Health Security: 
Pandemics, AMR & Security</a></p>
<div className="archive is-flex-tablet">
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/global-health-security-pandemics-amr-and-climate-change">
    <Img className="bounce" fixed={data.webinar_21.childImageSharp.fixed} />
  </a>
  <br />
   <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=xtELiC9lGPE">Youtube Video</a>
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
<p><strong>Host:</strong> Dr. Aula Abbara, Consultant & Honorary research fellow in Infectious Diseases, Imperial College London</p>

<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Prof. David Heymann</strong>, prev. Chairman, Public Health England & prof. of Infectious Disease Epidemiology, London School of Hygiene and Tropical Medicine, Policy Advisor of Royal Society of Tropical Medicine & Hygiene</li>
      <li><strong>Claudia Truppa</strong>, Public Health Specialist & Health coordinator, International Commitee of the Red Cross</li>
      <li><strong>Adam Roberts</strong>, Reader, Antimicrobial Chemotherapy and Resistance, Liverpool School of Tropical Medicine, Policy Advisor of Royal Society of Tropical Medicine & Hygiene</li>
      <li><strong>Uzma Bashir Aamir</strong>, COVID elad at the Program for monitoring Emerging Diseases (ProMed) & WHO Pakistan</li>
    </ul>
  </div>
</div>
</div>
</a>
<br />
</li>

  <li className="myli">
      <a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/management-of-breast-cancer-during-the-covid-19-pandemic">
Webinar 20: The Management of Breast cancer during the COVID19 Pandemic</a></p>
<div className="archive is-flex-tablet">
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/management-of-breast-cancer-during-the-covid-19-pandemic">
    <Img className="bounce" fixed={data.webinar_20.childImageSharp.fixed} />
  </a>
  <br />
   <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=Zcp9R-EDR7Y">Youtube Video</a>
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
<p><strong>Host:</strong> Mr. Daniel Leff, Consultant Breast Surgeon and Reader in Surgery, Imperial College London</p>

<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Dr. Jill Dietz</strong>, Breast Surgeon, ASBRS President and associate Professor of surgery at Case Western Reserve University School of Medicine</li>
      <li><strong>Profs. Galimberti & Dr. Montagna</strong>, European Institute of Oncology, IEO, Milan, Italy</li>
      <li><strong>Prof. Mitch Dowsett & Mr. Peter Barry</strong>, Royal Marsden NHS Foundation Trust and Institute of Cancer Research</li>
      <li><strong>Mr. Rajiv Dave</strong>, Consultant Oncoplastic Breast Surgeon, Manchester University Foundation NHS Trust</li>
      <li><strong>Dr. Susan Cleator</strong>, Clinical Oncologist, Imperial College Healthcare NHS Trust</li>
    </ul>
 {/* <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Safety:</b> We need 100% testing of all healthcare workers and full PPE until we know the status of the patient</li>
      <li><b>Structure:</b> We need strong leadership, emotional support and clear pathways avoiding all ambiguity. Agile operating systems are needed to respond to local needs in real time.</li>
      <li><b>Wider than the NHS:</b> we have a moral obligation to share our experiences to protect as many as we can. Refugees are particularly vulnerable. Social distancing is impossible and sanitation non-existent.</li>
 </ol> */}
  </div>
</div>
</div>
</a>
<br />
</li>


  <li className="myli">
      <a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/the-cns-and-anp-journey-redeployment-work-life-balance-and-supporting-cancer-patients">
Webinar 19: The CNS and AHP Journey, redeploymeny, work-life balance and supporting cancer care patients.</a></p>
<div className="archive is-flex-tablet">
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/the-cns-and-anp-journey-redeployment-work-life-balance-and-supporting-cancer-patients">
    <Img className="bounce" fixed={data.webinar_19.childImageSharp.fixed} />
  </a>
  <br />
 <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=bogXXxeuvfA">Youtube Video</a>
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
<p><strong>Host:</strong> Anne Moutadjer, Colorectal Nurse Specialist, Imperial College Healthcare Trust</p>

<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Mauro Properio</strong>, Colorectal Nurse Specialist</li>
      <li><strong>Lora Langford</strong>, Colorectal Nurse Specialist</li>
      <li><strong>Sali Putt</strong>, Skin Nurse Specialist</li>
      <li><strong>Ruth Smith</strong>, Physiotherapist and business lead at complete pilates</li>
    </ul>
 {/* <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Safety:</b> We need 100% testing of all healthcare workers and full PPE until we know the status of the patient</li>
      <li><b>Structure:</b> We need strong leadership, emotional support and clear pathways avoiding all ambiguity. Agile operating systems are needed to respond to local needs in real time.</li>
      <li><b>Wider than the NHS:</b> we have a moral obligation to share our experiences to protect as many as we can. Refugees are particularly vulnerable. Social distancing is impossible and sanitation non-existent.</li>
 </ol> */}
  </div>
</div>
</div>
</a>
<br />
</li>

  <li className="myli">
      <a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/surgical-training-global-ideas-concerns-and-expectations-in-the-pandemic">
Webinar 18: Surgical Training; Ideas, Concerns and Expectations in the pandemic</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/surgical-training-global-ideas-concerns-and-expectations-in-the-pandemic">
    <Img className="bounce" fixed={data.webinar_18.childImageSharp.fixed} />
  </a>
  <br />
  <div className="buttons">
    <a className="button is-darkBlue is-rounded" href={webinar18_slides} download="Webinar18_Slides.pdf">Slides</a>
    <a className="button is-darkBlue is-rounded" href={webinar18_infographic} download="Webinar18_Infographic.pdf">Infographic</a>
    <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=saw1ejIqQeE">Youtube Video</a>

  </div>
  {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Kathryn Chuquin</strong>, General Surgery Chief Resident, Mount Sinai Hospital - New York, USA.</li>
      <li><strong>Tobias Fritz</strong>, Orthopaedic Surgeon, Speaker of the Young Surgeons, German Surgical Society. Saarland University Medical Centre - Hamberg, Germany.</li>
      <li><strong>Francesco Maria Carrano</strong>, General Surgery Resident, Humanitas Clinical and Research Centre - Milan, Italy.</li>
      <li><strong>Lolade Giwa</strong>, Plastic Surgery Registrar, Vice President of Association of Surgeons in Training - UK.</li>
      <li><strong>Eric Van Der Stok</strong>, Surgical Trainee, Erasmus Medical Centre - Rotterdam, The Netherlands.</li>
    </ul>
 {/* <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Safety:</b> We need 100% testing of all healthcare workers and full PPE until we know the status of the patient</li>
      <li><b>Structure:</b> We need strong leadership, emotional support and clear pathways avoiding all ambiguity. Agile operating systems are needed to respond to local needs in real time.</li>
      <li><b>Wider than the NHS:</b> we have a moral obligation to share our experiences to protect as many as we can. Refugees are particularly vulnerable. Social distancing is impossible and sanitation non-existent.</li>
 </ol> */}
  </div>
</div>
</div>
</a>
<br />
</li>

  <li className="myli">
      <a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/bariatric-surgery-what-next-to-address-a-pandemic-within-a-pandemic">
Webinar 17: Bariatric Surgery, What next for the pandemic within a pandemic?</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/bariatric-surgery-what-next-to-address-a-pandemic-within-a-pandemic">
    <Img className="bounce" fixed={data.webinar_17.childImageSharp.fixed} />
  </a>
  <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=QhTj32RxP-k">Youtube Video</a>
  {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
<p><strong>Chair:</strong> Mr. Sanjay Purkayastha, Consultant Bariatric Surgeon and senior lecturer in Bariatric Surgery, Imperial College London, UK</p>
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Professor Phis Schauer</strong>, Director Pennington Biomedical Research Institute and Former President of the ASMBS, USA.</li>
      <li><strong>Professor Almino Ramos</strong>, Chief of Gastro-obeso-cimo, Sao Paolo and former IFSO President 2018-19, Brazil.</li>
      <li><strong>Professor Paulina Salminen</strong>, Chief of Surgery, Turku University, Finland.</li>
      <li><strong>Professor Kamal Mahawar</strong>, Consultant Bariatric Surgeon, Sunderland Royal Hospital and ASGBI Council for conferences, UK.</li>
    </ul>
 {/* <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Safety:</b> We need 100% testing of all healthcare workers and full PPE until we know the status of the patient</li>
      <li><b>Structure:</b> We need strong leadership, emotional support and clear pathways avoiding all ambiguity. Agile operating systems are needed to respond to local needs in real time.</li>
      <li><b>Wider than the NHS:</b> we have a moral obligation to share our experiences to protect as many as we can. Refugees are particularly vulnerable. Social distancing is impossible and sanitation non-existent.</li>
 </ol> */}
  </div>
</div>
</div>
</a>
<br />
</li>

  <li className="myli">
      <a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/restructuring-oesophagogastric-surgical-services-during-and-after-covid-19">
Webinar 16: Reconstructing eosophogastric surgical services during and after COVID-19.</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/restructuring-oesophagogastric-surgical-services-during-and-after-covid-19">
    <Img className="bounce" fixed={data.webinar_16.childImageSharp.fixed} />
  </a>
  <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=aCbp9UllOIE">Youtube Video</a>
  {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
{/* <p><strong>Chair:</strong> Prof. Tan Arulampalam, Consultant Surgeon, Colchester Hospital, UK. Professor of Surgery, school of medicine, Anglia Ruskin University, Essex, UK</p> */}
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Suzanna Gisbertz</strong>, Consultant Surgeon, Amsterdam UMC, Netherlands.</li>
      <li><strong>Ewen Griffiths</strong>, Consultant Surgeon, Queen Elizabeth Hospital, Birmingham, UK.</li>
      <li><strong>Lorenzo Ferri</strong>, Professor and Director of Division of Thoracic Surgery, McGill University, Quebec, Canada</li>
      <li><strong>Simon Law</strong>, Professor of gastrointestinal surgery, Division od eosophogeal and upper GI surgery, University of Hong Kong, OMH, Hong Kong</li>
      <li><strong>Wayne Hofstetter</strong>, Professor and Director of eosophogeal surgery, Programmd Anderson center, University of Texas, USA.</li>
    </ul>
 {/* <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Safety:</b> We need 100% testing of all healthcare workers and full PPE until we know the status of the patient</li>
      <li><b>Structure:</b> We need strong leadership, emotional support and clear pathways avoiding all ambiguity. Agile operating systems are needed to respond to local needs in real time.</li>
      <li><b>Wider than the NHS:</b> we have a moral obligation to share our experiences to protect as many as we can. Refugees are particularly vulnerable. Social distancing is impossible and sanitation non-existent.</li>
 </ol> */}
  </div>
</div>
</div>
</a>
<br />
</li>

    <li className="myli">
      <a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/how-to-proceed-with-surgery-in-lockdown-india-and-sri-lanka">
Webinar 15: Emerging from Surgical Hibernation after the COVID-19 Winter: Lessons from South Asia.</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/how-to-proceed-with-surgery-in-lockdown-india-and-sri-lanka">
    <Img className="bounce" fixed={data.webinar_15.childImageSharp.fixed} />
  </a>
  <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=X5YG5yPVt4I">Youtube Video</a>
  {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
<p><strong>Chair:</strong> Prof. Tan Arulampalam, Consultant Surgeon, Colchester Hospital, UK. Professor of Surgery, school of medicine, Anglia Ruskin University, Essex, UK</p>
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Prof. Shubhash Khanna</strong>, CMD and Chief consultant, Swagat Super Specialty Surgical Institute, Guwahati, India. Past President IAGES.</li>
      <li><strong>Prof. Bawantha Gamage</strong>, Head of Surgery, University of Sri Jayewardenepura, Consultant Surgeon, Colomba South Teaching Hospital, Sri Lanka.</li>
      <li><strong>Prof. Mark Ranjan Jesudason</strong>, Consultant General and Colorectal Surgeon, Clinical and Academic Lead, Christian Medical College, Vellore, India.</li>
    </ul>
 {/* <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Safety:</b> We need 100% testing of all healthcare workers and full PPE until we know the status of the patient</li>
      <li><b>Structure:</b> We need strong leadership, emotional support and clear pathways avoiding all ambiguity. Agile operating systems are needed to respond to local needs in real time.</li>
      <li><b>Wider than the NHS:</b> we have a moral obligation to share our experiences to protect as many as we can. Refugees are particularly vulnerable. Social distancing is impossible and sanitation non-existent.</li>
 </ol> */}
  </div>
</div>
</div>
</a>
<br />
</li>

<li className="myli">
<a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/bringing-global-health-experience-to-the-uks-covid-19-response">
Webinar 14: Bringing Global Health experience to the UK's COVID19 response.</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/bringing-global-health-experience-to-the-uks-covid-19-response">
    <Img className="bounce" fixed={data.webinar_14.childImageSharp.fixed} />
  </a>
  <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=vVvxFezI6cQ">Youtube Video</a>
  {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
  <p><strong>Chair:</strong> Dr Jess Hanlon, GP with Global Health Experience.</p>
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Dr. Afsana Safa</strong>, Refugee camps in Greece to GP Hubs in London</li>
      <li><strong>Kate Jarman</strong>, Working in Sierra Leone and Greece to working in London</li>
      <li><strong>Dr. Natalie Webber</strong>, Refugee Palliative Care in Uganda to London</li>
      <li><strong>Dr. Liz Hardmann</strong>, Anaesthetics in Zambia to the ICU in London.</li>
    </ul>
 {/* <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Safety:</b> We need 100% testing of all healthcare workers and full PPE until we know the status of the patient</li>
      <li><b>Structure:</b> We need strong leadership, emotional support and clear pathways avoiding all ambiguity. Agile operating systems are needed to respond to local needs in real time.</li>
      <li><b>Wider than the NHS:</b> we have a moral obligation to share our experiences to protect as many as we can. Refugees are particularly vulnerable. Social distancing is impossible and sanitation non-existent.</li>
 </ol> */}
  </div>
</div>
</div>
</a>
<br />
</li>

<li className="myli">
<a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/workplace-culture-during-the-covid-19-pandemic">
Webinar 13: Workplace culture during the COVID-19 Pandemic.</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/workplace-culture-during-the-covid-19-pandemic">
    <Img className="bounce" fixed={data.webinar_13.childImageSharp.fixed} />
  </a>
  <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=GI73-6zWTlA">Youtube Video</a>
  {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
 {/* <p><strong>Host:</strong> Miss Clare Rees, Paediatric Consultant, St. Mary's Hospital, London</p> */}
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Mr. Simon Fleming</strong>, Orthopaedic registrar and Vice Chair of the Academy of Medical Royal Colleges Trainee Doctors' group.</li>
      <li><strong>Imogen Wall</strong>, Journalist and Humanitarian aid worker.</li>
      <li><strong>Chloe Kitto</strong>, Wellness Team Lead, NHS Nightingale Hospital Induction and Training Programme.</li>
      <li><strong>Graeme Taylor</strong>, People Performance Specialist and Executive Team Coach.</li>
    </ul>
  <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Culture is a priority:</b> Developing a positive bottom-up and blame-free organisational culture is important in empowering individuals and teams.</li>
      <li><b>Defining roles:</b> ALL individuals should be identified and have defined roles to flatten organisational and team hierarchy.</li>
      <li><b>Wellness and wellbeing:</b> The concept of wellness can vary hugely for people and therefore it is vital that we meet needs on an individual basis.</li>
      <li><b>Long-term goals:</b> We should prepare and empower individuals now to help prevent mental health illness in the future. </li>
 </ol>
  </div>
</div>
</div>
</a>
<br />
</li>

<li className="myli">
<a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/does-surgical-research-have-a-role-during-a-pandemic">
Webinar 12: Does Surgical Research have a role during a pandemic?</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/does-surgical-research-have-a-role-during-a-pandemic">
    <Img className="bounce" fixed={data.webinar_12.childImageSharp.fixed} />
  </a>
  <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=79LDuR_l0KQ">Youtube Video</a>
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
 {/* <p><strong>Host:</strong> Miss Clare Rees, Paediatric Consultant, St. Mary's Hospital, London</p> */}
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Patricia Sylla</strong>, Professional Sciences Viewpoint, Associate Professor of Colorectal Surgery, Mount Sinai Hospital, New York.</li>
      <li><strong>Delia Cortés Guiral</strong>, Social Media Viewpoint - General and GI hospital Consultant Surgeon, King Khaled Hospital, Saudi Arabia, Hospital Universitario Principe De Asturias, Madrid.</li>
      <li><strong>Prof. Des Winter</strong>, Journals Viewpoint, Editor in Chief of the BJS.</li>
    </ul>
 <p><strong>Key points:</strong></p>
    <ol>
      <li>All Non-Covid related research has been detained world-wide to help redeploy staff members to the front line.</li>
      <li><b>A Collaborative Network:</b> The global academic and surgical communities need to align to share experiences to send a unified message by signing up to collaborative research.</li>
      <li><b>The role of surgical societies:</b> Offer up to date guidance and appraise surgical protocols during the pandemic to disseminate research responsibly.</li>
      <li><b>Digitalisation:</b> Allowing rapid dissemination of research, guidelines and opinions to form a global network representing all parts of the world</li>
      <li>The surgical community needs to adjust to these extraordinary changes and conceive resourceful and creative solutions in order to adapt.</li>
 </ol>
  </div>
</div>
</div>
</a>
<br />
</li>

<li className="myli">
<a>
   <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/improving-communications-during-covid-19-icon-a-junior-doctor-network">
Webinar 11: Improving Communications During Covid-19 - ICON-A Junior Doctor Network</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/improving-communications-during-covid-19-icon-a-junior-doctor-network">
    <Img className="bounce" fixed={data.webinar_11.childImageSharp.fixed} />
  </a>
  <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=8laSnTDa9Ik">Youtube Video</a>
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
 {/* <p><strong>Host:</strong> Miss Clare Rees, Paediatric Consultant, St. Mary's Hospital, London</p> */}
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Dr. Geiske Zijlstra</strong>, Acedemic Foundation Doctor, Imperial College London.</li>
      <li><strong>Dr. Rebekah Judge</strong>, Foundation Doctor, Imperial College London.</li>
      <li><strong>Dr. Georgie Phillips</strong>, Foundation Doctor, Imperial College London.</li>
      <li><strong>Dr. Peter Crook</strong>, Core medical Trainee, Imperial College London.</li>
    </ul>
    <p><strong>Panel:</strong></p>
    <ul>
      <li><strong>Dr. Arun Sivananthan</strong>, Clinical Research Fellow, Imperial College NHS Healthcare Trust.</li>
      <li><strong>Dr. Shiva Radhakrishnan</strong>, BRC Clinical Research Fellow, Imperial College NHS Healthcare Trust.</li>
      <li><strong>Dr. Matthew Machin</strong>, Academic Foundation Doctor, Imperial College London.</li>
    </ul>
 {/* <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Safety:</b> We need 100% testing of all healthcare workers and full PPE until we know the status of the patient</li>
      <li><b>Structure:</b> We need strong leadership, emotional support and clear pathways avoiding all ambiguity. Agile operating systems are needed to respond to local needs in real time.</li>
      <li><b>Wider than the NHS:</b> we have a moral obligation to share our experiences to protect as many as we can. Refugees are particularly vulnerable. Social distancing is impossible and sanitation non-existent.</li>
 </ol> */}
  </div>
</div>
</div>
</a>
<br />
</li>

<li className="myli">
<a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/covid-19-in-forced-migration-humanitarian-and-health-system-challenges">
Webinar 10: COVID19 in forced migration, humanitarian and health system challenges.</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/covid-19-in-forced-migration-humanitarian-and-health-system-challenges">
    <Img className="bounce" fixed={data.webinar_10.childImageSharp.fixed} />
  </a> <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=z6aaQ6GNBTE">Youtube Video</a>

  {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
 {/* <p><strong>Host:</strong> Miss Clare Rees, Paediatric Consultant, St. Mary's Hospital, London</p> */}
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Mr David Nott</strong>, Founder of David Nott Foundation and Vascular Surgeon at Imperial College NHS Healthcare Trust.</li>
      <li><strong>Dr. Miriam Orcutt</strong>, coordinator, UCL-Lancet Commission on migration and health.</li>
      <li><strong>Dr. Aula Abbara</strong>, Co-chair of the Syria Public Health Network.</li>
      <li><strong>Dr. Apostolos Veizis</strong>, Director of medical operational support unit for MSF, Greece.</li>
    </ul>
 {/* <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Safety:</b> We need 100% testing of all healthcare workers and full PPE until we know the status of the patient</li>
      <li><b>Structure:</b> We need strong leadership, emotional support and clear pathways avoiding all ambiguity. Agile operating systems are needed to respond to local needs in real time.</li>
      <li><b>Wider than the NHS:</b> we have a moral obligation to share our experiences to protect as many as we can. Refugees are particularly vulnerable. Social distancing is impossible and sanitation non-existent.</li>
 </ol> */}
  </div>
</div>
</div>
</a>
<br />
</li>

<li className="myli">
<a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/morale-and-wellbeing-in-the-healthcare-workforce-concerns-and-solutions-for-the-pandemic">
Webinar 9: Morale and wellbing in the Healthcare Workforce.</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/morale-and-wellbeing-in-the-healthcare-workforce-concerns-and-solutions-for-the-pandemic">
    <Img className="bounce" fixed={data.webinar9.childImageSharp.fixed} />
  </a> <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=S8AzkakLKJ0">Youtube Video</a>

  {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
 {/* <p><strong>Host:</strong> Miss Clare Rees, Paediatric Consultant, St. Mary's Hospital, London</p> */}
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Dr. Clare Gerada</strong>, Medical Director, the practitioner Health Programme, London.</li>
      <li><strong>Dr. Clare Warrell</strong>, Infectious Diseases and Tropical Medicine Registrar, London.</li>
      <li><strong>Mr. Chris Peters</strong>, Upper GI Surgery Consultant and Senior Lecturer, Imperial College London.</li>
    </ul>
  <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Surgical Barriers:</b> Surgeons are less vocal about personal anxieties and are at higher risk of burnout and mental health issues.</li>
      <li><b>Identify vulnerable groups:</b> It's vital to offer support and encouragement for vulnerable colleagues.</li>
      <li><b>Positive Team Culture</b> Clear and honest leadership strategies are needed and it's important to celebrate small wins.</li>
      <li><b>Targeted and Organisational Supprt:</b> Many Organisations have developed staff wellbeing zones offering one-on-one support and group therapy sessions. Support should be tailored for different surgical training levels.</li>
      <li><b>Top-Down Approach:</b> Lead consultants need to actively encourage junior members of staff to talk about issues and seek help.</li>
 </ol>
  </div>
</div>
</div>
</a>
<br />
</li>

<li className="myli">
<a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/how-to-win-a-new-future-and-not-lose-the-battle-for-the-past-strategic-lessons-for-covid-19">
Webinar 8: How to win a new future without losing the battle for today. Strategic lessons for COVID-19.</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/how-to-win-a-new-future-and-not-lose-the-battle-for-the-past-strategic-lessons-for-covid-19">
    <Img className="bounce" fixed={data.webinar8.childImageSharp.fixed} />
  </a> <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=jRRd2KydiZ0">Youtube Video</a>

  {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
 {/* <p><strong>Host:</strong> Miss Clare Rees, Paediatric Consultant, St. Mary's Hospital, London</p> */}
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Mike Granatt</strong>, Former head of the UK Cabinet Office Civil Contingencies Secretariat.</li>
      <li><strong>Chris Scott</strong>, Co-founder of RS3 Global, Risk Management Consultancy Crisis Response and Security Solutions.</li>
      <li><strong>Patrick Lagadec</strong>, Analyst and International Consultant in Crisis Intelligence.</li>
    </ul>
 {/* <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Safety:</b> We need 100% testing of all healthcare workers and full PPE until we know the status of the patient</li>
      <li><b>Structure:</b> We need strong leadership, emotional support and clear pathways avoiding all ambiguity. Agile operating systems are needed to respond to local needs in real time.</li>
      <li><b>Wider than the NHS:</b> we have a moral obligation to share our experiences to protect as many as we can. Refugees are particularly vulnerable. Social distancing is impossible and sanitation non-existent.</li>
 </ol> */}
  </div>
</div>
</div>
</a>
<br />
</li>

<li className="myli">
<a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/paediatric-surgery-in-the-covid-19-pandemic?utm_source=Livestorm+company+page">Webinar 7: Paediatric Surgery During the COVID-19 Pandemic</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/paediatric-surgery-in-the-covid-19-pandemic?utm_source=Livestorm+company+page">
    <Img className="bounce" fixed={data.webinar7.childImageSharp.fixed} />
  </a> <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=5ZzoxmSq0EI">Youtube Video</a>

  {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
 <p><strong>Host:</strong> Miss Clare Rees, Paediatric Consultant, St. Mary's Hospital, London</p>
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Dr. Shireen Nah</strong>, Associate Professor and Head of the Division of Paediatric Surgery, University of Malaysia.</li>
      <li><strong>Prof. Henry Rice</strong>, Chief and Professor of Paediatric Surgery at Duke University </li>
      <li><strong>Dr. Leopoldo Martinéz</strong>, Chief of Paediatric Surgery, Hospital Infantil La Paz, Madrid.</li>
      <li><strong>Dr. Alexis Arnaud</strong>, Paediatric Surgery, Rennes University Hospital.</li>
      <li><strong>Dr. Alessio Pini Prato</strong>, Paediatric Surgeon, The Children's Hospital, Alessandria.</li>
      <li><strong>Dr. Yuk Him Tam</strong>, Head of Paediatric Surgery, Prince of Wales Hospital.</li>

    </ul>
 {/* <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Safety:</b> We need 100% testing of all healthcare workers and full PPE until we know the status of the patient</li>
      <li><b>Structure:</b> We need strong leadership, emotional support and clear pathways avoiding all ambiguity. Agile operating systems are needed to respond to local needs in real time.</li>
      <li><b>Wider than the NHS:</b> we have a moral obligation to share our experiences to protect as many as we can. Refugees are particularly vulnerable. Social distancing is impossible and sanitation non-existent.</li>
 </ol> */}
  </div>
</div>
</div>
</a>
<br />
</li>

<li className="myli">
<a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/webinar6">Webinar 6: Colorectal Cancer in a Pandemic</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/webinar6">
    <Img className="bounce" fixed={data.webinar6.childImageSharp.fixed} />
  </a> <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=nlYKKod0O1E">Youtube Video</a>

  {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
{/* p><strong>Chair:</strong> Sanjay Purkayastha, Senior Lecturer Imperial College London, Consutlant Bariatric Surgeon, St Mary's Hospital, London</p> */}
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Prof Tan Arulampalam</strong>, Consultant colorectal surgeon and Professor of surgery, Anglia Ruskin University, Honorary Treasurer of the Association of Laparoscopic Surgeons</li>
      <li><strong>Mr Michael Machesney</strong>, Consultant colorectal surgeon, Whipps Cross Hospital, London, Chair of the Colorectal Cancer Clinical Reference Group</li>
      <li><strong>Mr Nader Francis</strong>, Consultant colorectal surgeon, Yeovil Hospital, Honorary Professor at UCL Division of Surgery and Interventional Science</li>
      <li><strong>Dr John Marks</strong>, Chief of Colorectal Surgery, Main Line Health System, President of the Multidisciplinary International Rectal Cancer Society and board of SAGES </li>
    </ul>
  <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Current Practice:</b> Colorectal services enduring COVID-19 pandemic will be noticeably different to what we are accustomed to.</li>
      <li><b>Colorectal Cancer Management:</b> Frequent practices will now invlude placement of endoluminal stents, short course radiotherapy and vigilant watchful waiting.</li>
      <li><b>Minimally Invasive Surgery:</b> Paucity of evidence but safer to avoid MIS, especially regarding robotic platforms.</li>
      <li><b>Pre-operative checks are a MUST:</b> Negative RT-PCRs, CT chest scans and inclusion of COVID related complications must be on ALL consent forms.</li>
 </ol>
  </div>
</div>
</div>
</a>
<br />
</li>

<li className="myli">
<a>
    <div className="tile is-child box has-background-white-ter">
<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/webinar5">Webinar 5: Integrated Crisis Management in the NHS for COVID-19</a></p>
<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image" style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/webinar5">
    <Img className="bounce" fixed={data.webinar5.childImageSharp.fixed} />
  </a> <br />
  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=DuovPNyPa7A">Youtube Video</a>

  {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
</div>
<br className="is-hidden-tablet"/>
<div className="content is-size-7" style={{maxWidth:'800px'}}>
<p><strong>Chair:</strong> Sanjay Purkayastha, Senior Lecturer Imperial College London, Consutlant Bariatric Surgeon, St Mary's Hospital, London</p>
<p><strong>Speakers:</strong></p>
    <ul>
      <li><strong>Mr David Nott</strong>, Founder of David Nott Foundation and Vascular Surgeon at Imperial College NHS Healthcare Trust</li>
      <li><strong>Mr Shahnawaz Rasheed</strong>, Medical Director of Humanity First and Consultant Colorectal Surgeon at Royal Marsden Hospital</li>
      <li><strong>Dominic Morgan</strong>, Head of Assessment at Humanity First</li>
    </ul>
 <p><strong>Key points:</strong></p>
    <ol>
      <li><b>Safety:</b> We need 100% testing of all healthcare workers and full PPE until we know the status of the patient</li>
      <li><b>Structure:</b> We need strong leadership, emotional support and clear pathways avoiding all ambiguity. Agile operating systems are needed to respond to local needs in real time.</li>
      <li><b>Wider than the NHS:</b> we have a moral obligation to share our experiences to protect as many as we can. Refugees are particularly vulnerable. Social distancing is impossible and sanitation non-existent.</li>
 </ol>
</div>
</div>
</div>
</a>
<br />
</li>

<li className="myli">
<a>
    <div className="tile is-child box has-background-white-ter">

      <p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/webinar4">Webinar 4: Surgeons from the frontline in Spain</a></p>

      <div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>

      <div className="archive-image" style={{marginRight:'1rem'}}>
      <a href="https://app.livestorm.co/pansurg-1/webinar4">
          <Img className="bounce" fixed={data.webinar4.childImageSharp.fixed} />
        </a> <br />
        <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=uV4DE0LTEIs">Youtube Video</a>

        {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
      </div>

      <br className="is-hidden-tablet"/>

      <div className="content is-size-7" style={{maxWidth:'800px'}}>

        <p><strong>Chair:</strong> Sanjay Purkayastha, Senior Lecturer Imperial College London, Consutlant Bariatric Surgeon, St Mary's Hospital, London</p>
        <p><strong>Speakers:</strong></p>
          <ul>
            <li><strong>Professor Ramon Vilallonga Puy</strong>, Professor of Surgery, Hospital Universitari Vall D'Hebron, Spain</li>
            <li><strong>Professor Jose Balibrea</strong>, Professor of Surgery, Hospital Clinic de Barcelona, Spain</li>
            <li><strong>Dr Salvador Morales-Conde</strong>, Chief of the unit of Innovation in Minimally Invasive Surgery, University Hospital Vigren de Roccio, Seville, Spain</li>
          </ul>
       <p><strong>Key points:</strong></p>
          <ol>
            <li>Both the government response and response from health system managers can be slow.
                It is important that clinicians remain vigilant and responsive so that they
                can drive appropriate changes on the ground.</li>
            <li>The effect on morale, particularly on junior team members can be profound.
                Those responsible for clinical teams and services need to be aware of this
                and think ahead about how to support them.</li>
            <li>There is a high rate of COVID-19 infection amongst healthcare workers.
                It is essential to protect staff on the frontline.
                This includes full PPE, with FFP3 or equivalent, in the operating room.</li>
        </ol>
        </div>

      </div>
      </div>
      </a>
      <br />
      </li>


<li className="myli">
<a>
        <div className="tile is-child box has-background-white-ter">
          <p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/webinar3">Webinar 3: Segration in surgery based on the experiences in Singapore</a></p>
          <div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
          <div className="archive-image" style={{marginRight:'1rem'}}>
          <a href="https://app.livestorm.co/pansurg-1/webinar3">
              <Img className="bounce" fixed={data.webinar3.childImageSharp.fixed} />
            </a>
            <br />
            <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=y9SRC-zq85k">Youtube Video</a>
            {/* <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Slides.pdf">Slides</a> */}
          </div>
          <br className="is-hidden-tablet"/>
          <div className="content is-size-7" style={{maxWidth:'800px'}}>
<p><strong>Chair:</strong> Guy Martin, Clinical Lecturer Imperial College London, Registrar in Vascular Surgery, St Mary's Hospital, London</p>
            <p><strong>Speakers:</strong></p>
              <ul>
                <li><strong>Doctor Raj Menon</strong>, Consultant vascular and trauma surgeon, National University Hospital of Singapore</li>
                <li><strong>Assistant Prof Andrew Choong</strong>, Consultant vascular Surgeon, National University Hospital of Singapore</li>
              </ul>
           <p><strong>Key points:</strong></p>
              <ol>
                <li>Social and institutional memory from the SARS pandemic has allowed Singapore
                  to very quickly instigate effective strategies both in the community and in hospitals,
                  including strict segregation of patient flow and clinical teams.</li>
                <li>No single set of thresholds regarding decision to operate can be effective.
                    Surgical teams need to adapt to where they are in the curve, know their local caseload
                    and services and then decide on what cases can be performed appropriately. </li>
                <li>Monitor your staff for strain and burnout. If you don't errors will occur.
                    Surveys can permit early identification of those struggling and staff
                    psychologists are invaluable for supporting teams, particular juniors.</li>
            </ol>
            </div>
          </div>
          </div>
          </a>
          <br />
          </li>

<li className="myli">
<a>
            <div className="tile is-child box has-background-white-ter">

<p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/webinar2">Webinar 2: Perioperative care in Italy</a></p>

<div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
<div className="archive-image " style={{marginRight:'1rem'}}>
<a href="https://app.livestorm.co/pansurg-1/webinar2">
    <Img className="bounce" fixed={data.webinar2.childImageSharp.fixed} />
  </a>
  <br />
  <div className="buttons">
    <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=Qojtpv2asyQ">Youtube Video</a>
    <a className="button is-darkBlue is-rounded" href={webinar2_notes} download="PanSurg_Webinar_Notes.pdf">Notes</a>
  </div>
</div>

<br className="is-hidden-tablet"/>

<div className="content is-size-7" style={{maxWidth:'800px'}}>
  <p>Due to the rapidly evolving situation, some of our speaker availability had changed.</p>
  <p><strong>Chair:</strong> James Kinross, Senior Lecturer Imperial College London, Consutlant Colorectal Surgeon, St Mary's Hospital, London</p>
  <p><strong>Speakers:</strong></p>
    <ol>
      <li><strong>Dottor Lorenzo Ball</strong>, Head of Upper GI Surgery, Humanitas Research Hospital,Rozzano, Italy</li>
      <li><strong>Dottor Andrea Mangini</strong>, Consultant Cardiac Surgeon, Luigi Sacco Hospital, Università degli Studi di Milano,</li>
      <li><strong>Professor Piergiorgio Danelli</strong>, Consultant General Surgeon, Head of General Surgery Department, Luigi Sacco Hospital, Università degli Studi di Milano</li>
    </ol>
  <p><strong>Key points:</strong></p>
    <ol>
      <li>Separate patients from the start, preferably with separate hospitals for covid and non-covid patients in a regional hub structure. </li>
      <li>Beware of asymptomatic patients on non-covid wards who have not been tested, and consider CT scanning patients who are high risk but have tested negative. </li>
      <li>Organisation of services should aim to expand ICU capacity as much as is physically possible, but patient selection is crucial to mitigate the effects of patient volume on the quality of care being delivered.</li>
    </ol>
  </div>
</div>
</div>
</a>
<br />
</li>

<li className="myli">
<a>
<div className="tile is-ancestor">
          <div className="tile is-parent">

            <div className="tile is-child box has-background-white-ter">
              <p className="title is-size-4 has-text-red"><a href="https://app.livestorm.co/pansurg-1/pansurg-webinar1">Webinar 1: The Italian Experience</a></p>
              <div className="archive is-flex-tablet" style={{justifyContent:'flex-start'}}>
              <div className="archive-image" style={{marginRight:'1rem'}}>
                <a href="https://app.livestorm.co/pansurg-1/pansurg-webinar1">
                  <Img className="bounce" fixed={data.webinar1.childImageSharp.fixed} />
                </a>
                <br />
                <div className="buttons">
                  <a className="button is-darkBlue is-rounded" href="https://www.youtube.com/watch?v=-imQbXU9nL8">Youtube Video</a>
                  <a className="button is-darkBlue is-rounded" href={webinar1_slides} download="PanSurg_Webinar_Slides.pdf">Slides</a>
                </div>
              </div>
              <br className="is-hidden-tablet"/>
              <div className="content is-size-7" style={{maxWidth:'800px'}}>
                <p><strong>Chair:</strong> James Kinross, Senior Lecturer Imperial College London, Consutlant Colorectal Surgeon, St Mary's Hospital, London</p>
                <p><strong>Speakers:</strong></p>
                  <ul>
                    <li><strong>Prof Carlo Castoro</strong>, Head of Upper GI Surgery, Humanitas Research Hospital, Rozzano, Italy</li>
                    <li><strong>Prof Antonino Spinelli</strong>, Head of Colon and Rectal Surgery, Humanitas Research Hospital, Rozzano, Italy</li>
                    <li><strong>Prof Riccardo Rosati</strong>, Head of GI Surgery, Vita-Salute San Raffaele University, Milan, Italy</li>
                  </ul>
                <p><strong>Key points:</strong></p>
                  <ol>
                    <li>Protecting staff is absolutely paramount.
                    Make sure your staff are equipped with PPE and that they use it. Bear in mind
                    that wearing PPE is restrictive and staff may only be able to cope with it
                    for 6 hours at a time. Plan work forces appropriately.</li>
                    <li>Viral detection is crucial and not always easy. Patients may be asymptomatic
                    and test negative on PCR but have evidence of significant disease on chest CT. Consider
                    performing chest CT on patients pre-operatively to "screen" for COVID-19.</li>
                    <li>Given that detection is difficult, treat patients as "positive" until proven otherwise.
                    Have a strategy of separation to minimise transmission in secondary care.</li>
                  </ol>
                </div>
              </div>
            </div>
            </div>
            </div>
            </a>
            <br />
            </li>
          </ul>
            <BackToTopButton />
      </div>
    </section>

  </>
  )
}
